import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { StrictMode, useState } from 'react';
import registerServiceWorker from './registerServiceWorker';
import { BrowserRouter } from "react-router-dom";

ReactDOM.render(
    <StrictMode>
        <BrowserRouter basename="">
            <App />
        </BrowserRouter>
    </StrictMode>,
    document.getElementById('root')
);

registerServiceWorker();
