import React, { Component } from 'react';

class Portfolio extends Component {
  render() {

    if(this.props.data) {
        var appdescription = this.props.data.appdescription;
        var appname = this.props.data.appname;
    }

    return (
        <section id="portfolio">
            <div className="row">

                <div className="two columns header-col">
                   <img class="s-about__pic" src="images/icon_114_114.png" alt="" />
                </div>

                <div className="ten columns flex-container">
                <h1><span>{appname}</span></h1>
                  <ul className="slides">
                      {appdescription}
                  </ul>
                </div>
            </div>
       </section>
    );
  }
}

export default Portfolio;
